<template>
  <div>
    <v-row class="not-print ma-1">
      <v-col cols="3">
        <app-date-field
          @input="select()"
          v-model="sarchParams.start_date"
          label="Data Inicial"
        />
      </v-col>
      <v-col cols="3">
        <app-date-field
          @input="select()"
          v-model="sarchParams.end_date"
          label="Data Final"
        />
      </v-col>
    </v-row>

    <div id="common">
      <div class="page" size="A4">
        <br />

        <h2>
          {{ account.name }} - {{ $format.dateBr(sarchParams.start_date) }} à
          {{ $format.dateBr(sarchParams.end_date) }}
        </h2>
        <br />
        <v-row>
          <v-col>
            <h3>Totais por Método de Pagamento</h3>
            <v-data-table
              :headers="paymentMethodTable"
              :items="paymentMethodSum"
              :items-per-page="-1"
              hide-default-footer
              disable-sort
              dense
            >
              <template v-slot:[`item.payment_method`]="{ item }">
                {{ item.payment_method.label }}
              </template>
              <template v-slot:[`item.value_sum`]="{ item }">
                {{ $format.moneyBr(item.value_sum) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3>Totais por Origem</h3>
            <v-data-table
              :headers="originTable"
              :items="originSum"
              :items-per-page="-1"
              hide-default-footer
              disable-sort
              dense
            >
              <template v-slot:[`item.payment_method`]="{ item }">
                {{ item.payment_method.label }}
              </template>
              <template v-slot:[`item.value_sum`]="{ item }">
                {{ $format.moneyBr(item.value_sum) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3>Totais por Plano de contas</h3>
            <v-data-table
              :headers="accountPlanTable"
              :items="accountPlanSum"
              :items-per-page="-1"
              hide-default-footer
              disable-sort
              dense
            >
              <template v-slot:[`item.account_plan`]="{ item }">
                {{ item.account_plan.code }} - {{ item.account_plan.name }}
              </template>
              <template v-slot:[`item.value_sum`]="{ item }">
                {{ $format.moneyBr(item.value_sum) }}
              </template>
            </v-data-table>
            <br />
            <span class="pt-1"> impresso em {{ $format.dateBr(date) }} </span>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import AccountTransactionLabel from "@/components/account/sections/AccountTransactionLabel.vue";
import AccountTransactionDialog from "@/components/account/sections/AccountTransactionDialog";
import AccountSelect from "@/components/account/ui/AccountSelect.vue";
import paymentMethods from "@/enums/paymentMethods";
import transactionOrigin from "@/enums/transactionOrigin";

export default {
  components: {
    AccountTransactionLabel,
    AccountTransactionDialog,
    AccountSelect,
  },

  data() {
    return {
      paymentMethods,
      transactionOrigin,

      account: {},
      originSum: [],
      accountPlanSum: [],
      paymentMethodSum: [],

      date: new Date().toISOString().substr(0, 10),

      accountPlanTable: [
        { text: "Plano de contas", value: "account_plan" },
        { text: "Valor", value: "value_sum", width: "100px" },
      ],

      paymentMethodTable: [
        { text: "Método de pagamento", value: "payment_method" },
        { text: "Valor", value: "value_sum", width: "100px" },
      ],

      sarchParams: {
        account_id: this.$route.params.id,
        start_date: new Date().toISOString().substr(0, 10),
        end_date: new Date().toISOString().substr(0, 10),
      },

      originTable: [
        { text: "Origem", value: "origin.text" },
        { text: "Valor", value: "value_sum", width: "100px" },
      ],
    };
  },

  async created() {
    this.select();
  },

  methods: {
    async select(page = 1) {
      this.$loading.start();
      await this.$http
        .index("account/account-transaction-report", this.sarchParams)
        .then((response) => {
          this.accountPlanSum = response.accountPlanSum;
          this.paymentMethodSum = this.mapPaymentMethod(
            response.paymentMethodSum
          );
          this.originSum = this.mapTransactionOrigin(response.originSum);
          this.account = response.account;
          this.$loading.finish();
        });
    },
    mapPaymentMethod(paymentMethod) {
      return paymentMethod.map((item) => {
        return {
          ...item,
          payment_method: this.paymentMethods.find(
            (row) => row.value == item.payment_method
          ),
        };
      });
    },
    mapTransactionOrigin(origin) {
      return origin.map((item) => {
        return {
          ...item,
          origin: this.transactionOrigin.find(
            (row) => row.value == item.origin
          ),
        };
      });
    },
  },
};
</script>
<style  lang="scss">
@import "@/assets/style/prints/CommonPrinting.scss";
</style>
