var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"not-print ma-1"},[_c('v-col',{attrs:{"cols":"3"}},[_c('app-date-field',{attrs:{"label":"Data Inicial"},on:{"input":function($event){return _vm.select()}},model:{value:(_vm.sarchParams.start_date),callback:function ($$v) {_vm.$set(_vm.sarchParams, "start_date", $$v)},expression:"sarchParams.start_date"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('app-date-field',{attrs:{"label":"Data Final"},on:{"input":function($event){return _vm.select()}},model:{value:(_vm.sarchParams.end_date),callback:function ($$v) {_vm.$set(_vm.sarchParams, "end_date", $$v)},expression:"sarchParams.end_date"}})],1)],1),_c('div',{attrs:{"id":"common"}},[_c('div',{staticClass:"page",attrs:{"size":"A4"}},[_c('br'),_c('h2',[_vm._v(" "+_vm._s(_vm.account.name)+" - "+_vm._s(_vm.$format.dateBr(_vm.sarchParams.start_date))+" à "+_vm._s(_vm.$format.dateBr(_vm.sarchParams.end_date))+" ")]),_c('br'),_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Totais por Método de Pagamento")]),_c('v-data-table',{attrs:{"headers":_vm.paymentMethodTable,"items":_vm.paymentMethodSum,"items-per-page":-1,"hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.payment_method",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.payment_method.label)+" ")]}},{key:"item.value_sum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.moneyBr(item.value_sum))+" ")]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Totais por Origem")]),_c('v-data-table',{attrs:{"headers":_vm.originTable,"items":_vm.originSum,"items-per-page":-1,"hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.payment_method",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.payment_method.label)+" ")]}},{key:"item.value_sum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.moneyBr(item.value_sum))+" ")]}}],null,true)})],1)],1),_c('v-row',[_c('v-col',[_c('h3',[_vm._v("Totais por Plano de contas")]),_c('v-data-table',{attrs:{"headers":_vm.accountPlanTable,"items":_vm.accountPlanSum,"items-per-page":-1,"hide-default-footer":"","disable-sort":"","dense":""},scopedSlots:_vm._u([{key:"item.account_plan",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.account_plan.code)+" - "+_vm._s(item.account_plan.name)+" ")]}},{key:"item.value_sum",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$format.moneyBr(item.value_sum))+" ")]}}],null,true)}),_c('br'),_c('span',{staticClass:"pt-1"},[_vm._v(" impresso em "+_vm._s(_vm.$format.dateBr(_vm.date))+" ")])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }